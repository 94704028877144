import { ModalEtapaVcpNotaComponent } from "./modal-etapa-vcp-nota/modal-etapa-vcp-nota.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize, switchMap } from "rxjs/operators";
import {
  ContainerNota,
  NotaCertificacao,
  StatusNota,
} from "src/app/entities/NotaCertificacao";
import { Status } from "src/app/entities/status";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { EtapaABR } from "../../entities/etapas";
import { AlertModalService } from "../../services/alert-modal.service";
import { Services } from "../../services/service";
import { SpinnerService } from "../../services/spinner.service";
import { ToastService } from "../../services/toast.service";
import { VcpService } from "../../services/vcp.service";
import { ToastType } from "src/app/entities/toast";
import { ApiResponse } from "src/app/entities/api-response";
import { NotaService } from "src/app/services/nota.service";
import { NotaExplicativaService } from "src/app/nota-explicativa.service";
import * as Sentry from "@sentry/angular";
import { AppService } from "src/app/services/app-service";
import { PermissionService } from "src/app/services/permission-service";
import { PerfilUsuario } from "src/app/entities/perfil";
import { BasicModalComponent } from "src/app/shared/basic-modal/basic-modal.component";
import { BasicModalConfig } from "src/app/shared/basic-modal/basic-modal.config";

type ConcluirVcp = "unidade-reprovada" | "finalizar-processo";

@Component({
  selector: "modal-etapa-vcp",
  templateUrl: "./modal-etapa-vcp.component.html",
  styleUrls: ["./modal-etapa-vcp.component.scss"],
})
export class ModalEtapaVcp {
  @Input() notaCertificacao: NotaCertificacao;
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() status: Status;
  @Output() concluirVCP = new EventEmitter();

  @ViewChild("modalSelectOptionVcp")
  private modalSelectOptionVcp: BasicModalComponent;

  public modalSelectOptionVcpConfig: BasicModalConfig = {
    modalTitle: ``,
    hideDenyButton: true,
    hideSaveButton: false,
    hideCloseButton: false,
    colorCloseButton: "secondary",
    saveButtonLabel: "Sim",
    options: {
      centered: false,
      size: "md",
    },
    onClose: async () => {
      return;
    },
    onSave: async () => {
      if (this.statusModalVcp === "unidade-reprovada") {
        this.ConcluirVcpUnidadeReprovada();
      } else if (this.statusModalVcp === "finalizar-processo") {
        this.ConcluirVcpFinalizarProcesso();
      }
    },
  };

  textBodyModal: string = "";
  statusModalVcp: ConcluirVcp;

  usuarioLogado: any;
  notaAtingida: number;
  podeConcluirVcp = false;
  notaExplicativa: Boolean = false;
  podeAcessarVcp: Boolean = false;
  iconInfo = faInfoCircle;
  etapaAtual: any;
  containerNota: ContainerNota;
  loadingNotaVcp = false;

  vcpSafraVigenteEmAndamento = false;

  podeSolicitarRetorno: boolean = false;

  podeReenviarEmail = false;
  podeVerNotaExplicativa = false;

  mapaStatusSolicitacaoRetorno: EtapaABR[] = [
    EtapaABR.VCP_CONCLUIDA,
    EtapaABR.VCP_REPROVADA,
    EtapaABR.CERTIFICACAO_EM_EMISSAO,
  ];
  podeVerOutrasOpcoes: boolean;

  constructor(
    private vcpService: VcpService,
    private service: Services,
    private appService: AppService,
    private toastService: ToastService,
    private spinnerService: SpinnerService,
    private alertService: AlertModalService,
    private modalService: NgbModal,
    private notaService: NotaService,
    private permissionService: PermissionService,
    private notaExplicativaService: NotaExplicativaService
  ) {
    this.containerNota = this.notaService.iniciarContainerNota();
  }

  private _podeSolicitarRetorno() {
    this.podeSolicitarRetorno = this.appService.safraSelecionadaEhVigente();
    if (this.podeSolicitarRetorno == true) {
      let safraEscolhida = JSON.parse(
        window.localStorage.getItem("safraEscolhida")
      );
      this.service
        .get(
          `UpCertificacao/listastatus/${this.unidadeProdutiva.id}/${safraEscolhida.id}`
        )
        .subscribe((res) => {
          let resfilter = res.filter((x) => x.id != 27);
          let lastItem = resfilter.pop();
          this.etapaAtual = lastItem;
          this.podeSolicitarRetorno = this.mapaStatusSolicitacaoRetorno.some(
            (etapa) => this.etapaAtual.descricao === etapa
          );
        });
    }
  }

  ngOnInit(): void {
    this.loading();
    this.podeConcluirVcp = this.status.etapa === EtapaABR.VCP_CONCLUIDA;
  }

  loading() {
    this.usuarioLogado = JSON.parse(window.localStorage.getItem("usuario"));
    this.BuscarNotaNecessaria();
    this.BuscarNotaObtida();
    this._podeSolicitarRetorno();

    this.vcpSafraVigenteEmAndamento =
      this.status.etapa === EtapaABR.VCP_EM_ANDAMENTO_INCOMPLETA ||
      this.status.etapa === EtapaABR.VCP_EM_ANDAMENTO_COMPLETA_NAO_CONCLUIDA;

    this.containerNota = this.notaService.iniciarContainerNota();
    this._carregarStatusNotaVCP();
    this.notaExplicativaService
      .verificaNotaExplicativa(this.unidadeProdutiva)
      .subscribe((res) => (this.notaExplicativa = res));

    this._podeReenviarEmail();

    this.podeAcessarVcp = this.status.etapa !== EtapaABR.NAO_INICIADO;
    let perfil = this.permissionService.getPerfisValidos();
    this.podeVerOutrasOpcoes = !perfil.some(
      (x) => x == PerfilUsuario.CERTIFICADORA_AUDITOR
    );
  }

  get vcpReprovado(): boolean {
    const reprovado = this.containerNota.meta?.aprovado === false;
    return this.possuiNota && reprovado;
  }
  get vcpAprovado(): boolean {
    const aprovado = this.containerNota.meta?.aprovado === true;
    return this.possuiNota && aprovado;
  }

  get possuiNota(): boolean {
    return this.containerNota.temNota;
  }

  get exibirToolTip(): boolean {
    return !this.containerNota.temNota;
  }

  preencherVcp() {
    if (!this.podeAcessarVcp) return;
    this.spinnerService.show();

    var windowReference = window.open();
    this.vcpService.buscarFormulario(this.unidadeProdutiva).subscribe(
      (x) => {
        this.spinnerService.hide();
        const url = x?.url + x?.hash;
        windowReference.location.href = url;
      },
      (err) => {
        this.spinnerService.hide();
        Sentry.captureException(
          "Erro ao acessar o formulário, verificar se URL do formulário está correto."
        );
        this.toastService.showToast(
          ToastType.DANGER,
          "Error",
          "Erro ao acessar o formulário, favor entrar em contato com a ABRAPA."
        );
      }
    );
  }

  onOpenModalSelectOptionVcp(statusModalVcp: ConcluirVcp) {
    this.statusModalVcp = statusModalVcp;

    if (statusModalVcp === "unidade-reprovada") {
      this.modalSelectOptionVcpConfig.modalTitle =
        "Confirmar o encerramento da etapa VCP com resultado de reprovação? ";
      this.textBodyModal =
        " Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VCP e não será permitido também que a UP realize o processo de Certificação e se for o caso do Licenciamento!";
    } else if (statusModalVcp === "finalizar-processo") {
      this.modalSelectOptionVcpConfig.modalTitle =
        "Confirmar o encerramento da etapa VCP, liberando a UP para a próxima etapa de emissão de Certificado ABR e se for o caso do Licenciamento BCI?";
      this.textBodyModal =
        "Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VCP!";
    }
    this.modalSelectOptionVcp.open();
  }

  ConcluirVcpUnidadeReprovada() {
    if (this.podeReprovarVcp) return;

    this.spinnerService.show();
    this.vcpService
      .concluirVcpUnidadeProdutivaReprovada(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((x) => {
        if (x.code === 201) {
          this.toastService.showToast(ToastType.SUCCESS, "Sucesso", x.message);
          this.loading();
          this.concluiuVcp();
        } else {
          this.toastService.showToast(ToastType.DANGER, "Error", x.message);
        }
        this._carregarStatusNotaVCP();
      });

    // this.alertService
    // .showConfirm(
    //   "Confirmar o encerramento da etapa VCP com resultado de reprovação?",
    //   "Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VCP e não será permitido também que a UP realize o processo de Certificação e se for o caso do Licenciamento!"
    // ) .subscribe((confirmed) => {
    //   if (confirmed) {
    //     this.spinnerService.show();
    //     this.vcpService
    //       .concluirVcpUnidadeProdutivaReprovada(this.unidadeProdutiva)
    //       .pipe(
    //         finalize(() => {
    //           this.spinnerService.hide();
    //         })
    //       )
    //       .subscribe((x) => {
    //         if (x.code === 201) {
    //           this.toastService.showToast(
    //             ToastType.SUCCESS,
    //             "Sucesso",
    //             x.message
    //           );
    //           this.loading();
    //           this.concluiuVcp();
    //         } else {
    //           this.toastService.showToast(
    //             ToastType.DANGER,
    //             "Error",
    //             x.message
    //           );
    //         }
    //         this._carregarStatusNotaVCP();
    //       })
    //     }
    //   });
  }

  ConcluirVcpFinalizarProcesso() {
    if (this.podeFinalizarVcp) return;

    this.spinnerService.show();
    this.vcpService
      .concluirVcpFinalizarProcesso(this.unidadeProdutiva.id)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((x) => {
        this._carregarStatusNotaVCP();
        if (x.code === 201) {
          this.toastService.showToast(ToastType.SUCCESS, "Sucesso", x.message);
          this.loading();
          this.concluiuVcp();
        } else {
          this.toastService.showToast(ToastType.DANGER, "Error", x.message);
        }
      });

    // this.alertService
    // .showConfirm(
    //   "Confirmar o encerramento da etapa VCP, liberando a UP para a próxima etapa de emissão de Certificado ABR e se for o caso do Licenciamento BCI?",
    //   "Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VCP!”"
    // )  .subscribe((confirmed) => {
    //   if (confirmed) {
    //     this.spinnerService.show();
    //     this.vcpService
    //       .concluirVcpFinalizarProcesso(this.unidadeProdutiva.id)
    //       .pipe(
    //         finalize(() => {
    //           this.spinnerService.hide();
    //         })
    //       )
    //       .subscribe((x) => {
    //         this._carregarStatusNotaVCP();
    //         if (x.code === 201) {
    //           this.toastService.showToast(
    //             ToastType.SUCCESS,
    //             "Sucesso",
    //             x.message
    //           );
    //           this.loading();
    //           this.concluiuVcp();
    //         } else {
    //           this.toastService.showToast(
    //             ToastType.DANGER,
    //             "Error",
    //             x.message
    //           );
    //         }
    //       });
    //     }
    //   }
    // );
  }

  BuscarNotaNecessaria() {
    this.vcpService
      .getNotaNecessariaSafra(this.unidadeProdutiva.codigoSafra)
      .subscribe((x) => {
        this.notaCertificacao = x;
      });
  }

  BuscarNotaObtida() {
    this.vcpService.getNotaObtida(this.unidadeProdutiva).subscribe((x) => {
      this.notaAtingida = x;
    });
  }

  private _carregarStatusNotaVCP() {
    this.loadingNotaVcp = true;
    this.vcpService
      .getNotasSafraSelecionada(this.unidadeProdutiva)
      .pipe(finalize(() => (this.loadingNotaVcp = false)))
      .subscribe((res) => {
        this.containerNota = res;
      });
  }

  open(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  reenviarEmail() {
    this.spinnerService.show();
    this.vcpService
      .reenviarEmail(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((res) => {
        this.toastService.showToast(ToastType.SUCCESS, "Success", res.message);
      });
  }

  preencherNotaExplicativa() {
    console.log("TODO: Redirecionar para preencher nota explicativa");

    // TODO!!
    // this.service
    //   .post("EtapaVcp/BuscarFormulario", certificacao)
    //   .subscribe((x) => {
    //     window.open(x?.url + x?.hash, "_blank");
    //   });
  }

  concluiuVcp() {
    this.concluirVCP.emit();
  }

  private _podeReenviarEmail() {
    this.vcpService
      .getStatusAtualSafraSelecionada(this.unidadeProdutiva)
      .subscribe((statusAtual) => {
        this.podeReenviarEmail =
          statusAtual.etapa === EtapaABR.VCP_CONCLUIDA ||
          statusAtual.etapa === EtapaABR.VCP_EM_ANDAMENTO;
        this.podeVerNotaExplicativa =
          statusAtual.etapa === EtapaABR.VCP_EM_ANDAMENTO ||
          statusAtual.etapa === EtapaABR.VCP_CONCLUIDA ||
          statusAtual.etapa === EtapaABR.VCP_APROVADA ||
          statusAtual.etapa === EtapaABR.CERTIFICACAO_EM_EMISSAO ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_NAO_OPTANTE_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_LICENCIADO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_REPROVADO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_CANCELADA_BCI;
      });
  }

  get podeFinalizarVcp(): boolean {
    return !(this.vcpAprovado && this.podeConcluirVcp);
  }

  get podeReprovarVcp(): boolean {
    return !(this.vcpReprovado && this.podeConcluirVcp);
  }
}
