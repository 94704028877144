<app-modal-container
  (changeStep)="onChangeStep($event)"
  [unidadeProdutiva]="unidadeProdutiva"
  [steps]="steps"
  (toggleModalStatus)="modalAberto = $event"
  [loading]="loading"
>
  <ng-container *ngIf="modalAberto">
    <app-modal-etapa-licenca-bci
      *ngIf="activeStepLabel === 'Licença BCI'"
      [unidadeProdutiva]="unidadeProdutiva"
    >
    </app-modal-etapa-licenca-bci>

    <modal-etapa-gestao-abrapa
      *ngIf="activeStepLabel === 'Gestão ABRAPA'"
      [unidadeProdutiva]="unidadeProdutiva"
    >
    </modal-etapa-gestao-abrapa>
  </ng-container>
</app-modal-container>
