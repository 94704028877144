export class NotaCertificacao {
  id: number;
  descricaoSafra: string;
  safra: string;
  percentual: number;
}

export interface Nota {
  id: number;
  criterio: string;
  porcentagemPerguntas: number;
  aprovadoPerguntas: boolean;
  aprovadoPerguntasConformidade: boolean;
  quantidadeItensTotal: number;
  quantidadeItensCertificacao: number;
  quantidadeItensCMP: number;
  itensReprovados: string;
  itensNaoRespondidos: string;
}

export interface MetaNota {
  aprovado: boolean;
  notaMinimaPerguntas: number;
  perguntasReprovadasCMP: string;
}

export enum StatusNota {
  APROVADO = "Aprovado",
  REPROVADO = "Reprovado",
  NAO_INICIADO = "Não iniciado",
}

export interface ContainerNota {
  data: Nota[];
  meta: MetaNota;
  temNota: boolean;
  statusNota: StatusNota;
}
