<div class="p-3 main-content-responsive" style="background-color: #fff">
  <p class="h4">
    <strong>{{ title }}</strong>
  </p>
  <div class="vdp-identification row">
    <div class="col-md-6">
      <p class="text-muted h6">Unidade Produtiva</p>
      <p class="h5">
        <strong>{{ unidadeProdutiva.nomeUnidadeProdutiva | titlecase }}</strong>
      </p>
    </div>
    <div class="col-md-6">
      <p class="text-muted h6">Safra de certificação</p>
      <p class="h5">
        <strong>{{ unidadeProdutiva.safraCertificacao }}ª</strong>
      </p>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-6">
      <p class="h6">Nota mínima necessária para perguntas de certificação</p>
      <p class="h5">
        <fa-icon style="font-size: 0.5rem" [icon]="icon" size="sm"></fa-icon>
        &nbsp;<strong
          >{{
            containerNota?.meta?.notaMinimaPerguntas ||
              getNotaPorSafra(unidadeProdutiva)
          }}
          %</strong
        >
      </p>
    </div>
    <div class="col-md-6">
      <p class="h6">
        Nota mínima necessária para perguntas de conformidade obrigatória
      </p>
      <p class="h5">
        <fa-icon style="font-size: 0.5rem" [icon]="icon" size="sm"></fa-icon
        >&nbsp;<strong>100 %</strong>
      </p>
    </div>
  </div>
  <hr />
  <ng-container *ngIf="containerNota.data && containerNota.data.length > 0">
    <p>
      <strong> Nota obtida por Critério </strong>
    </p>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="col-2">
              <p class="text-muted text-start mx-3">Critérios</p>
            </th>
            <th>
              <p class="text-muted text-center">Itens</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens de Certificação</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens (CMP)</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens de certificação</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">
                Itens de conformidade obrigatória (eliminatória)
              </p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens Reprovados</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens não respondidos</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of containerNota.data">
            <td class="col-2 text-start">
              <strong style="margin-left: 1rem">{{ i.criterio || "" }}</strong>
            </td>
            <td class="text-center">
              <strong>{{ i.quantidadeItensTotal || "" }}</strong>
            </td>
            <td class="text-center">
              <strong>{{ i.quantidadeItensCertificacao || "" }}</strong>
            </td>
            <td class="text-center">
              <strong>{{ i.quantidadeItensCMP }}</strong>
            </td>
            <td class="text-center">
              <span
                [ngClass]="{
                  'dot-green': i.aprovadoPerguntas === true,
                  'dot-red': i.aprovadoPerguntas === false
                }"
              >
              </span>
              {{ i.porcentagemPerguntas || 0 }} %
            </td>
            <td class="text-center">
              <span
                [ngClass]="{
                  'dot-green': checkIsApproved(i),
                  'dot-red': !checkIsApproved(i)
                }"
              >
              </span>
              {{ checkIsApproved(i) ? "Aprovado" : "Reprovado" }}
            </td>
            <td class="text-center mx-4">
              <strong>
                <span *ngIf="i?.itensReprovados.length < 12">{{
                  i.itensReprovados ? (i.itensReprovados | slice : 0 : 11) : 0
                }}</span>
                <span
                  *ngIf="i?.itensReprovados.length >= 12"
                  placement="left"
                  [ngbTooltip]="i.itensReprovados"
                  >{{
                    i.itensReprovados
                      ? (i.itensReprovados | slice : 0 : 11)
                      : 0
                  }}...</span
                >
              </strong>
            </td>
            <td class="text-center mx-4">
              <strong>
                <span *ngIf="i?.itensNaoRespondidos.length < 12">
                  {{
                    i.itensNaoRespondidos
                      ? (i.itensNaoRespondidos | slice : 0 : 11)
                      : 0
                  }}
                </span>
                <span
                  *ngIf="i?.itensNaoRespondidos.length >= 12"
                  placement="left"
                  [ngbTooltip]="i.itensNaoRespondidos"
                >
                  {{
                    i.itensNaoRespondidos
                      ? (i.itensNaoRespondidos | slice : 0 : 11)
                      : 0
                  }}...
                </span>
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />
    <p>
      <strong> Total </strong>
    </p>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="col-2">
              <p class="text-muted text-start mx-3">Critérios</p>
            </th>
            <th>
              <p class="text-muted text-center">Itens</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens de Certificação</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens (CMP)</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens de certificação</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">
                Itens de conformidade obrigatória (eliminatória)
              </p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens Reprovados</p>
            </th>
            <th scope="col">
              <p class="text-muted text-center">Itens não respondidos</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-start">
              <p class="total-criterios">
                {{ totalCriterios }}
              </p>
            </td>
            <td class="text-center">{{ totalItens }}</td>
            <td class="text-center">{{ totalItensDeCertificacao }}</td>
            <td class="text-center">{{ totalItensCMB }}</td>
            <td class="text-center">
              <span [class]="setDotColor()"> </span>
              {{ totalPorcentagem }} %
            </td>
            <td class="text-center">
              <span
                [ngClass]="{
                  'dot-green': conformidadeObrigatoria,
                  'dot-red': !conformidadeObrigatoria
                }"
              >
              </span>
              {{ conformidadeObrigatoria ? "Aprovado" : "Reprovado" }}
            </td>
            <td class="text-center">{{ totalItensReprovados }}</td>
            <td class="text-center">{{ totalItensNaoRespondidos || 0 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <div *ngIf="!containerNota.temNota || !containerNota.data.length">
    <p>
      Os Critérios e Itens poderão ser visualizados a medida que o formulário
      for preenchido e salvo
    </p>
  </div>
  <hr />
  <div
    *ngIf="containerNota.data && containerNota.data.length > 0"
    class="results-content"
  >
    <div class="label-result-content">
      <span class="label-result">
        <strong>Resultado final:</strong>
      </span>
      <span class="label-result">
        <strong>
          <span
            [ngClass]="{
              'dot-green-large': conformidadeObrigatoria,
              'dot-red-large': !conformidadeObrigatoria
            }"
          >
          </span>
          {{ conformidadeObrigatoria ? "Aprovado" : "Reprovado" }}
          ({{ totalPorcentagem }}%)
          {{ cmbReprovado === true ? "**" : "" }}
          {{
            cmbReprovado === false && certificacaoReprovado === true ? "*" : ""
          }}
        </strong>
      </span>
    </div>
    <div class="label-result-content" *ngIf="cmbReprovado">
      <!-- Colocar tooltip -->
      <label
        class="label-result"
        [ngbTooltip]="containerNota?.meta?.perguntasReprovadasCMP"
      >
        <b>Reprovação em Itens (CMP)**</b>
      </label>
    </div>
    <div class="label-result-content" *ngIf="certificacaoReprovado">
      <label class="label-result">
        <b>Reprovação em Itens de Certificação*</b>
      </label>
    </div>
  </div>
  <div class="modal-footer footer-content-responsive mt-4">
    <button type="button" class="btn btn-light" (click)="onClose()">
      Fechar
    </button>
  </div>
</div>
