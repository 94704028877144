import { Component, Input, OnInit } from "@angular/core";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { Step } from "src/app/shared/multi-steps/multi-steps.component";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "modal-bci-abrapa",
  templateUrl: "./modal-bci-abrapa.component.html",
  styleUrls: ["./modal-bci-abrapa.component.scss"],
})
export class ModalBciArapaComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;

  steps: Step[];
  defaultActiveStepLabel: string;
  activeStepLabel: string;

  modalAberto = false;
  loading = true;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this._defineSteps();
  }

  private _defineSteps() {
    this.loading = true;

    this.steps = [
      {
        label: "Gestão ABRAPA",
        active: true,
      },
      {
        label: "Licença BCI",
        active: true,
      },
    ];
    this.defaultActiveStepLabel = "Gestão ABRAPA";
    this.activeStepLabel = this.defaultActiveStepLabel;
    this.modalService.changeDefaultActiveStepLabel(this.defaultActiveStepLabel);

    this.loading = false;
  }

  onChangeStep(step: Step) {
    this.activeStepLabel = step.label;
  }
}
