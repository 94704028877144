import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ContainerNota } from "./../../entities/NotaCertificacao";
import { UnidadeProdutiva } from "./../../entities/unidade-produtiva";
import { Component, Input, OnInit } from "@angular/core";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Helpers } from "src/app/utils/helpers";
import { SpinnerService } from "src/app/services/spinner.service";

@Component({
  selector: "app-modal-notas",
  templateUrl: "./modal-notas.component.html",
  styleUrls: ["./modal-notas.component.scss"],
})
export class ModalNotasComponent implements OnInit {
  @Input() title: string;
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() containerNota: ContainerNota;
  @Input() modal: any;

  limit = 12;

  totalCriterios: number = 0;
  totalItens: number = 0;
  totalItensDeCertificacao: number = 0;
  totalItensCMB: number = 0;
  totalPorcentagem: number = 0;
  totalItensReprovados: number = 0;
  totalItensNaoRespondidos: number = 0;

  conformidadeObrigatoria: boolean = false;
  cmbReprovado: boolean = false;
  certificacaoReprovado: boolean = false;

  icon = faCircle;

  constructor(
    private modalService: NgbModal,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.validateTotal();
      this.spinnerService.hide();
    }, 500);
  }

  getNotaPorSafra(unidadeProdutiva: UnidadeProdutiva): number {
    let notaMinima = 90;

    if (unidadeProdutiva.safraCertificacao === 1) {
      notaMinima = 85;
    }

    if (unidadeProdutiva.safraCertificacao === 2) {
      notaMinima = 87;
    }

    if (unidadeProdutiva.safraCertificacao === 3) {
      notaMinima = 89;
    }

    return notaMinima;
  }

  validateTotal(containerNota: ContainerNota = this.containerNota) {
    // Quantidade de critérios
    this.totalCriterios = containerNota?.data?.filter(
      (x) => x.criterio
    )?.length;

    containerNota?.data?.forEach((item) => {
      // Total de itens
      this.totalItens += item?.quantidadeItensTotal;
      // Total de itens de certificação
      this.totalItensDeCertificacao += item?.quantidadeItensCertificacao;
      // Total de itens CMB
      this.totalItensCMB += item?.quantidadeItensCMP;
      // Total de itens reprovados
      this.totalItensReprovados += this.calculaItemString(
        item?.itensReprovados
      ).length;
      // Total de itens não respondidos
      this.totalItensNaoRespondidos += this.calculaItemString(
        item?.itensNaoRespondidos
      ).length;

      // Total da porcentagen
      this.totalPorcentagem = Number(
        (
          ((this.totalItensDeCertificacao - this.totalItensReprovados) * 100) /
          this.totalItensDeCertificacao
        ).toFixed(2)
      );

      // TODO: Fazer a validação de certificação
      this.certificacaoReprovado =
        containerNota?.data?.filter(
          (x) =>
            x.porcentagemPerguntas < containerNota?.meta?.notaMinimaPerguntas
        ).length != 0;
    });

    if (this.containerNota.meta.perguntasReprovadasCMP !== "") {
      this.cmbReprovado = true;
    }

    // Conformidade obrigatoria
    this.conformidadeObrigatoria = containerNota?.data?.every((obrigatorio) => {
      return (
        obrigatorio?.aprovadoPerguntas &&
        obrigatorio?.aprovadoPerguntasConformidade
      );
    });

    if (!this.totalItensCMB) {
      this.totalItensCMB = 0;
    }
    if (isNaN(this.totalPorcentagem)) {
      this.totalPorcentagem = 0;
    }
  }

  setDotColor(): string {
    const notaMinimaAprovacao = Number(
      this.containerNota?.meta?.notaMinimaPerguntas
    ).toFixed(2);
    const totalPorcentagem = Number(this.totalPorcentagem)?.toFixed(2);
    if (totalPorcentagem >= notaMinimaAprovacao) {
      return "dot-green";
    } else {
      return "dot-red";
    }
  }

  buscaTipoConformidade(tipoConformidade: string) {
    return Helpers.verificaCorTipoConformidade(tipoConformidade);
  }

  calculaItemString(items: string) {
    const listItems = items
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item !== "");
    return listItems;
  }

  showTooltipReproved(items: string) {
    const listItems = items.split(",");

    if (listItems && listItems?.length > this.limit) {
      return listItems;
    }
    return listItems && listItems?.length > 0 ? listItems : null;
  }

  onClose() {
    this.modal.close();
  }

  checkIsApproved(item: any) {
    return item?.aprovadoPerguntas && item?.aprovadoPerguntasConformidade;
  }
}
