const redColor = "red";
const greenColor = "green";
const orangeColor = "orange";

const corTipoConformidadeMapping = {
  ["APROVADO".toLowerCase()]: `dot-${greenColor}`,
  ["REPROVADO".toLowerCase()]: `dot-${redColor}`,
  ["CANCELADO".toLowerCase()]: `dot-${redColor}`,
  ["EM_ANALISE".toLowerCase()]: `dot-${orangeColor}`,
};

export { corTipoConformidadeMapping };
